<template>
  <div class="construction-contain">
    <img class="logo" alt="Vue logo" src="../../public/images/logo.png">

    <div class="text-wrap">
      <h1>Website</h1>
      <h2>Under Construction</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style lang="scss" scoped>
.construction-contain {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .logo {
    width: 30%;
  }

  h1, h2 {
    font-family: 'Comfortaa', cursive;
    // color: #1d1b1b;
    color: #a72924;
    text-shadow: 0px 2px 1px rgba(0,0,0,0.2);
  }
  h1 {
    font-size: 5rem;
    letter-spacing: 15px;
  }

  h2 {
    font-size: 2rem;
    letter-spacing: 3px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .construction-contain {

    .logo {
      width: 80%;
    }

    h1 {
      font-size: 2.5rem;
      margin-bottom: 5px;
    }

    h2 {
      font-size: 1.2rem;
    }
  }
}
</style>
